import { getPublicationStatus } from '@gn/core/content/utils/client';
import { getResizedCloudinaryImage } from '@gn/core/integrations/cloudinary';
import { ContentCategory, ContentPublicationStatus } from '@gn/core/type';
import { UPCOMING_WORKSHOP_DATE_FORMAT } from '@gn/core/config/workshops';
import classNames from 'classnames';
import format from 'date-fns/format';
import Link from 'next/link';
import { getContentUrl } from '@gn/core/utils/url';
import BookmarkButton from '../BookmarkButton';
import UpcomingIcon from '../icons/UpcomingIcon';
import RecordingIcon from '../icons/RecordingIcon';
import { useQuery } from '@blitzjs/rpc';
import getVideoProgresses from 'app/video-progress/queries/getVideoProgresses';
import { CurrentUserContext } from '../CurrentUserContext';
import React, { PropsWithChildren, useContext } from 'react';
import { ContentListContent } from './types';
import { useSession } from '@blitzjs/auth';
import DOMPurify from 'isomorphic-dompurify';
import CheckIcon from '../icons/CheckIcon';

interface WithContent {
    content: ContentListContent;
}

export function BlockedOverlay({ content }: WithContent) {
    return (
        <div className="article-box__overlay ab-overlay">
            <a href={getContentUrl(content)} onClick={onBlockedClick} className="ab-overlay__lock">
                <svg
                    className="icon icon-lock"
                    width="32"
                    height="36"
                    viewBox="0 0 32 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.57143 11.25V15.75C2.0467 15.75 0 17.7647 0 20.25V31.5C0 33.9853 2.0467 36 4.57143 36H27.4286C29.9533 36 32 33.9853 32 31.5V20.25C32 17.7647 29.9533 15.75 27.4286 15.75V11.25C27.4286 5.0368 22.3118 0 16 0C9.68817 0 4.57143 5.0368 4.57143 11.25ZM22.8571 15.75V11.25C22.8571 7.52208 19.7871 4.5 16 4.5C12.2129 4.5 9.14286 7.52208 9.14286 11.25V15.75H22.8571ZM19 24C19 24.8885 18.6138 25.6868 18 26.2361V29C18 30.1046 17.1046 31 16 31C14.8954 31 14 30.1046 14 29V26.2361C13.3863 25.6868 13 24.8885 13 24C13 22.3431 14.3431 21 16 21C17.6569 21 19 22.3431 19 24Z"
                        fill="#fff"
                    />
                </svg>
            </a>
        </div>
    );
}
interface IAccessLinnk {
    content: Pick<ContentListContent, 'title' | 'slug'>;
    openLinksInNewTab?: boolean;
}
export function AccessLink({ content, openLinksInNewTab }: IAccessLinnk) {
    return (
        <Link
            href={getContentUrl(content)}
            prefetch={false}
            className="article-box__link-cover"
            target={openLinksInNewTab ? '_blank' : undefined}
        >
            <span style={{ opacity: 0 }}>{content.title}</span>
        </Link>
    );
}

interface CardHeaderProps extends WithContent {
    socialShare?: boolean;
    children?: React.ReactNode;
    openLinksInNewTab?: boolean;
    bookmarkVisible?: boolean;
}

export function CardHeader(props: CardHeaderProps) {
    const { content, socialShare, bookmarkVisible } = props;

    const prefix = socialShare ? 'og-' : '';
    const contentStatus = getPublicationStatus(content);
    return (
        <div
            className={classNames(`${prefix}article-box__top`, {
                [`${prefix}article-box__height`]: !content.event?.brand?.icon,
            })}
        >
            {content.category !== ContentCategory.Article &&
                content.category !== ContentCategory.Announcement &&
                content.event?.brand?.icon && (
                    <div className="cat">
                        <img
                            width={30}
                            height={30}
                            src={getResizedCloudinaryImage(content.event.brand.icon, 60) || ''}
                            alt={content.event.name}
                        />
                        <span className="cat__name">{content.event.name}</span>
                    </div>
                )}
            {contentStatus === ContentPublicationStatus.Upcoming && (
                <RightSideWrapper socialShare={socialShare}>
                    <UpcomingIndicator {...props}>
                        {content.startDate ? format(content.startDate, UPCOMING_WORKSHOP_DATE_FORMAT) : null}
                    </UpcomingIndicator>
                </RightSideWrapper>
            )}
            {contentStatus === ContentPublicationStatus.Pending && (
                <RightSideWrapper socialShare={socialShare}>
                    <RecordingIndicator {...props} />
                </RightSideWrapper>
            )}
            {contentStatus === ContentPublicationStatus.Finished && (
                <RightSideWrapper socialShare={socialShare}>
                    <FinishedIndicator {...props} />
                </RightSideWrapper>
            )}
            {contentStatus === ContentPublicationStatus.Published && <ContentDuration {...props} />}
            {!socialShare && bookmarkVisible && <BookmarkButton {...props} isIcon />}
        </div>
    );
}

interface CardDesciption extends WithContent {
    isWorkshop?: boolean;
}
export function CardDescription({ content, isWorkshop }: CardDesciption) {
    const descriptionContent = content.text;
    const contentStatus = getPublicationStatus(content);
    const contentUpcoming = contentStatus === ContentPublicationStatus.Upcoming;
    return isWorkshop ? (
        <div className="article-box__wrap-text">
            {descriptionContent && (
                <div
                    className={`article-box__text  ${contentUpcoming ? ' article-box__text--effects' : ''}`}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(descriptionContent.replace(/&nbsp;/g, ' '), {
                            ALLOWED_TAGS: ['br'],
                        }),
                    }}
                />
            )}
            {contentUpcoming && (
                <div className="article-box__button">
                    <Link href={getContentUrl(content)} prefetch={false} className="btn btn--primary">
                        Register
                    </Link>
                </div>
            )}
        </div>
    ) : null;
}

function RightSideWrapper({ socialShare, children }) {
    return !socialShare ? <div style={{ marginLeft: 'auto', display: 'inline-flex' }}>{children}</div> : children;
}

export function ContentDuration({ content, socialShare }: CardHeaderProps) {
    const prefix = socialShare ? 'og-' : '';

    return content.duration && content.duration > 1 ? (
        <div className={`${prefix}time`}>
            <svg
                className={`${prefix}icon icon-clock`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                id="clock"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 8a5 5 0 100 10 5 5 0 000-10zm-7 5a7 7 0 1114 0 7 7 0 01-14 0zm7-3.7c.6 0 1 .5 1 1v2.3l1.7 1.7a1 1 0 01-1.4 1.4l-2-2a1 1 0 01-.3-.7v-2.7c0-.5.4-1 1-1z"
                ></path>
                <path d="M9 4c0-.6.4-1 1-1h4c.6 0 1 .4 1 1s-.4 1-1 1h-4a1 1 0 01-1-1z"></path>
            </svg>
            {Math.round(content.duration / 60)} min
        </div>
    ) : null;
}

export const UpcomingIndicator = ({ socialShare, children }: PropsWithChildren<CardHeaderProps>) => {
    const prefix = socialShare ? 'og-' : '';

    return (
        <div className={`${prefix}time upcoming`}>
            <UpcomingIcon className={`${prefix}icon icon-upcoming`} />
            {children ?? 'Upcoming'}
        </div>
    );
};

export const RecordingIndicator = ({ socialShare, children }: PropsWithChildren<CardHeaderProps>) => {
    const prefix = socialShare ? 'og-' : '';

    return (
        <div className={`${prefix}time upcoming`}>
            <RecordingIcon className={`${prefix}icon icon-recording`} />
            {children ?? 'Recording pending'}
        </div>
    );
};

export const FinishedIndicator = ({ socialShare, children }: PropsWithChildren<CardHeaderProps>) => {
    const prefix = socialShare ? 'og-' : '';

    return (
        <div className={`${prefix}time upcoming`}>
            <CheckIcon className={`${prefix}icon icon-recording`} />
            {children ?? 'Workshop finished'}
        </div>
    );
};

export function onBlockedClick(e) {
    alert('For multipass subscribers only');
}

const CONSIDER_VIDEO_FULLY_WATCHED_BEFORE_END_PERCENT = 80;

export function VideoProgress({ content }: WithContent) {
    const currentUser = useContext(CurrentUserContext);
    const session = useSession();

    const [videoProgresses] = useQuery(getVideoProgresses, null, {
        enabled: !!session.userId || !!currentUser,
        cacheTime: 0,
    });

    const videoProgress = videoProgresses?.find((videoProgress) => videoProgress.contentId === content.id);
    const videoDuration = content.duration ?? null;
    let watchedPercent;
    if (videoProgress && videoDuration) {
        watchedPercent = Math.floor((videoProgress.currentWatchedPositionSeconds / videoDuration) * 100);
        if (watchedPercent >= CONSIDER_VIDEO_FULLY_WATCHED_BEFORE_END_PERCENT) {
            watchedPercent = 100;
        }
        if (watchedPercent < 3) {
            watchedPercent = 3;
        }
    }
    return !!watchedPercent ? (
        <div className="video-preview-progress-bar">
            <div className="video-preview-progress-bar__background" />
            <div className="video-preview-progress-bar__progress" style={{ width: `${watchedPercent}%` }} />
        </div>
    ) : null;
}
